@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

html,
body {
  font-family: "Rubik";

  --shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);

  --background-color: #eff4f9;
  --primary: rgb(116, 201, 233);
}
