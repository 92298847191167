.input {
  position: relative;
  width: 100%;
  height: 40px;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  + .input {
    margin-top: 16px;
  }

  input {
    height: 100%;
    width: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 34px;
    border-radius: 2px;
    outline: none;
    border: none;
    box-shadow: var(--shadow);

    &::placeholder {
      color: #222;
      opacity: 0.3;
    }
  }

  .input-icon {
    position: absolute;
    left: 12px;
    top: calc(50% + 1px);
    transform: translate(0, -50%);
    height: 16px;
    width: 16px;
    color: #222;
    opacity: 0.5;
  }

  .input-error {
    width: 100%;
    margin: 0px;
    margin-top: 6px;
    margin-left: 8px;
    font-size: 14px;
    line-height: 16px;
    color: var(--red);
  }

  .input-error-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
    height: 16px;
    width: 16px;
  }
}
