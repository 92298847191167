.terms-root-container {
  height: 100%;
  max-width: 680px;
  padding-bottom: 0;
  overflow-y: hidden;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);

  .content {
    height: calc(100% - 204px);
    padding: 16px;
    overflow-y: scroll;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      justify-content: space-between;

      color: var(--primary);
      font-weight: 600;

      .logo {
        width: auto;
        max-width: 30%;
        height: 72px;
        object-fit: contain;
      }

      .text {
        font-size: 24px;
        margin: 0;
        flex: 1;
        text-align: end;
      }
    }

    .terms {
      flex: 1;

      .section-title {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.25px;
        padding: 0;
        margin: 0;
        text-align: left;
        margin-top: 24px;
        margin-bottom: 6px;
        &:nth-of-type(1) {
          margin-top: 12px;
        }
      }

      .section-text {
        padding: 0;
        margin: 0;
        margin-bottom: 8px;
        text-align: left;
      }
    }
  }

  .signature-bar {
    height: 204px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    border-color: black;
    border-width: 1px;
    background-color: white;
    padding-bottom: 24px;

    width: 100%;

    .canvas {
      // flex: 1;
      width: 100%;
      height: 84px;
      // border: 1px solid red;
    }

    .separator-bar {
      height: 1px;
      width: calc(100% - 64px);
      margin: 0px 32px;
      border-top: 1px dashed #bbbbbb;
    }

    .subtitle {
      text-align: center;
      color: #bbbbbb;
    }

    .buttons {
      flex: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      height: 40px;
      padding: 0px 16px;
      justify-content: space-evenly;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 12px;
        border-radius: 8px;

        &:hover {
          opacity: 0.9;
        }

        &.disabled {
          opacity: 0.3;

          &:hover {
            opacity: 0.3;
          }
          &.active {
            opacity: 0.3;
          }
        }
      }

      .reset {
        // background-color: red;
        border: 3px solid #999999;
        color: #999999;

        &:active {
          opacity: 0.7;
        }
      }

      .accept {
        background-color: var(--primary);
        color: white;

        &:active {
          opacity: 0.8;
        }
      }
    }
  }
}
