.button {
  height: 40px;
  min-width: 130px;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  outline: none;
  border: 2px solid var(--primary);
  color: var(--primary);
  background-color: transparent;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  cursor: pointer;
  text-decoration: none;

  &:active {
    opacity: 0.7;
  }

  &.light {
    color: var(--white);
    border-color: var(--white);

    &:active {
      opacity: 0.6;
    }
  }

  &.primary {
    padding: 0 12px;
    color: var(--white);
    background-color: var(--primary-color);

    &:active {
      opacity: 0.9;
    }
  }

  &.negative {
    padding: 0 12px;
    color: var(--white);
    background-color: var(--red);
    border-color: var(--red);

    &:active {
      opacity: 0.9;
    }
  }

  &.cancel {
    min-width: auto;
    color: var(--gray);
    border-color: var(--gray);
    font-weight: 500;

    &:active {
      opacity: 0.6;
    }
  }

  &.disabled {
    opacity: 0.3;
    cursor: default;
  }
}
